<template>
  <c-grid class="teaser-carousel">
    <template #header>
      <slot name="header"/>
    </template>

    <c-carousel
      :autoplay:="false"
      :slides-to-show="4"
      :slides-to-scroll="4"
      :adaptive-height="false"
      :responsive="[
        {
          breakpoint: $root.scss.grid['breakpoint-xl-down'].value,
          settings: {
            autoplay: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            adaptiveHeight: false
          }
        },
        {
          breakpoint: $root.scss.grid['breakpoint-md-down'].value,
          settings: {
            autoplay: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            adaptiveHeight: false
          }
        },
        {
          breakpoint: $root.scss.grid['breakpoint-sm-down'].value,
          settings: {
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false
          }
        }
      ]"
    >
      <slot/>
    </c-carousel>

    <template #footer>
      <slot name="footer"/>
    </template>
  </c-grid>
</template>

<script>
export default {
  name: 'TeaserCarousel'
}
</script>

<style lang="scss">
.teaser-carousel {
  .carousel {
    margin-top: 0;
    margin-bottom: 0;

    .slick-list {
      @include make-row();

      .slick-track {
        align-items: stretch;
      }

      .slick-slide {
        > div {
          @include make-col-ready();
        }
      }
    }
  }
}
</style>
