var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-grid',{staticClass:"teaser-carousel",scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._t("header")]},proxy:true},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},[_c('c-carousel',{attrs:{"autoplay:":false,"slides-to-show":4,"slides-to-scroll":4,"adaptive-height":false,"responsive":[
      {
        breakpoint: _vm.$root.scss.grid['breakpoint-xl-down'].value,
        settings: {
          autoplay: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          adaptiveHeight: false
        }
      },
      {
        breakpoint: _vm.$root.scss.grid['breakpoint-md-down'].value,
        settings: {
          autoplay: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          adaptiveHeight: false
        }
      },
      {
        breakpoint: _vm.$root.scss.grid['breakpoint-sm-down'].value,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: false
        }
      }
    ]}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }